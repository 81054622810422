"use client";
import MainLayout from "@/components/layout/mainLayout";
import { useEffect, useState } from "react";
import BuyNowCart from "@/components/common/BuyNowCart";
import SignupForFree from "@/components/landingpage/SignupForFree";
import {
  getCheckoutLineItems,
  setRemoveLineItemInCart,
  setUpdateQuantityInCart,
} from "@/util/utils";

import ScoMeta from "@/components/scometa";
import NewNumber868Banner from "@/components/number868Collection/NewNumber868Banner";
import NewNumber868AboutUs from "@/components/number868Collection/NewNumber868AboutUs";

import SpecialCharity from "@/components/number868Collection/specialCharity";

import GalarySection from "@/components/number868Collection/galarySection";
import Number868TastingNots from "@/components/number868Collection/Number868TastingNots";
import FoundationDetails from "@/components/number868Collection/FoundationDetails";

const Number868Collection = (props: any) => {
  const { data, meta, countryCode } = props;

  const [number868collectionData, setNumber868collectionData]: any =
    useState(null);
  const [checkout, setCheckout] = useState({ checkout: { lineItems: [] } });
  const [openModal, setOpenModal]: any = useState(false);

  useEffect(() => {
    if (data) {
      setNumber868collectionData(data?.data?.attributes?.blocks);
    }
  }, [data]);
  const renderNumber868CollectionPage = () => {
    return (
      <>
        {number868collectionData && number868collectionData.length
          ? number868collectionData.map((content: any, index: any) => {
              switch (content.__component) {
                case "number868.introducing":
                  return (
                    <div key={index}>
                      <NewNumber868Banner data={content} />
                    </div>
                  );
                case "octave.about-the-collection":
                  return (
                    <NewNumber868AboutUs
                      key={content?.id + index}
                      data={content}
                    />
                  );
                case "number868.tasting-note":
                  return (
                    <Number868TastingNots
                      key={content?.id + index}
                      data={content}
                    />
                  );

                case "octave.journey":
                  return (
                    <SpecialCharity key={content?.id + index} data={content} />
                  );

                case "singlecask.house-cooperage":
                  return (
                    <FoundationDetails
                      key={content?.id + index}
                      data={content}
                    />
                  );
                case "sections.gallery":
                  return (
                    <>
                      {content?.is_active === true && (
                        <GalarySection
                          key={content?.id + index}
                          data={content}
                        />
                      )}
                    </>
                  );

                default:
                  return null;
              }
            })
          : null}
      </>
    );
  };

  const toggleCartSidebar = () => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });

    setOpenModal(!openModal);
  };

  useEffect(() => {
    getCheckoutLineItems(countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  }, []);

  const updateQuantityInCart = (lineItemId: any, quantity: any) => {
    const lineItemsToUpdate = [
      { id: lineItemId, quantity: parseInt(quantity, 10) },
    ];
    setUpdateQuantityInCart(lineItemsToUpdate, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };

  const removeLineItemInCart = (lineItemId: any) => {
    setRemoveLineItemInCart(lineItemId, countryCode).then((res) => {
      setCheckout({
        ...res,
      });
    });
  };
  return (
    <>
      <BuyNowCart
        countryCode={countryCode}
        openModal={openModal}
        toggleCartSidebar={toggleCartSidebar}
        checkout={checkout}
        updateQuantityInCart={updateQuantityInCart}
        removeLineItemInCart={removeLineItemInCart}
      />
      <head>
        <ScoMeta meta={meta ? meta : {}} />
      </head>
      <MainLayout checkout={checkout} toggleCartSidebar={toggleCartSidebar}>
        <div className="single-cask-collection">
          {number868collectionData && renderNumber868CollectionPage()}

          <SignupForFree />
        </div>
      </MainLayout>
    </>
  );
};

export default Number868Collection;
