import Link from "next/link";
import React from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../common/CommonButton/Button";
import { formatImageUrl } from "../../../helper/helper";

const Number868TastingNots = (props: any) => {
  let bgImage: any = formatImageUrl(
    window.innerWidth <= 760
      ? props?.data?.background_mobile?.data?.attributes?.url
      : props?.data?.background?.data?.attributes?.url
  );
  return (
    <section
      className="number_868_collection_tasting_nots"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="collection_container collection_container868">
        <Row>
          <Col sm={12} md={12} lg={6}>
            <div className="tasting_text_div">
              <h2>{props?.data?.title}</h2>
              <p>{props?.data?.content}</p>

              <div className="tasting_nots_details_section">
                {props?.data?.details?.length > 0 &&
                  props?.data?.details?.map((ele: any, index: any) => {
                    return (
                      <div className="details" key={index}>
                        <h5>{ele?.title}</h5>
                        <h6>{ele?.value}</h6>
                      </div>
                    );
                  })}
              </div>
              {props?.data?.cta?.link ? (
                <Button
                  btnText={props?.data?.cta?.title}
                  btnClass={"tasting_nots_btn_buy"}
                  btnLink={props?.data?.cta?.link}
                  linkOpenState={
                    props?.data?.cta?.open_in_new_tab == true ? "_blank" : ""
                  }
                />
              ) : null}
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div className="tasting_nots_img_section">
              <img
                src={props?.data?.media?.data?.attributes?.url}
                alt={`tasting nots img`}
                width={653}
                height={659}
                className="tasting_not_main_img"
              />
            </div>
          </Col>
        </Row>
        <div className="number_868_tasting_box">
          <div className="number_868_toptext">{props?.data?.testing_title}</div>

          <div className="number_868_testing_nots_box_section">
            <Row className="number_868_row_of_testing_notes">
              {props?.data?.testing_items?.length > 0 &&
                props?.data?.testing_items?.map((item: any, index: any) => {
                  return (
                    <Col xs={12} md={6} lg={6} xl={3} key={index}>
                      <div className="testing_box">
                        {item?.media?.data && (
                          <img
                            src={item?.media?.data?.attributes?.url}
                            alt="img"
                          />
                        )}
                        <h2>{item?.name}</h2>
                        <p>{item?.value}</p>
                      </div>
                    </Col>
                  );
                })}
            </Row>
          </div>

          <div className="images_section_of_tasting">
            <Row>
              {props?.data?.items?.length > 0 &&
                props?.data?.items?.map((item: any, index: number) => {
                  return (
                    <Col xs={4} sm={4} md={4} lg={2} xl={2} key={index}>
                      <img
                        src={item?.media?.data?.attributes?.url}
                        alt={`tasting img`}
                        width={430}
                        height={508}
                        className="six_images_section"
                      />
                      <h3>{item?.name}</h3>
                    </Col>
                  );
                })}
            </Row>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Number868TastingNots;
