import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const GalarySection = (props: any) => {
  const { data } = props;

  var settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    arrows: true,
    draggable: true,
    centerMode: true,
    variableWidth: true,
    centerPadding: "0%",

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
          draggable: true,
          centerMode: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
          draggable: true,
          centerMode: true,
          variableWidth: true,
        },
      },
      {
        breakpoint: 500,
        settings: {
          dots: false,
          infinite: true,
          speed: 500,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: false,
          arrows: true,
          draggable: true,
          centerMode: true,
          centerPadding: "1%",
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <div
      className="galary-container868"
      style={{
        backgroundImage: `url("/number868/galary.png")`,
      }}
    >
      <div className="gallery_slider_man">
        <Slider {...settings}>
          {data?.items?.length > 0 &&
            data?.items?.map((item: any, index: any) => {
              return (
                <div key={index} className="gallary-image-wrapper">
                  <img
                    src={
                      item?.media?.data ? item.media.data.attributes.url : ""
                    }
                    alt="gallery image"
                  />
                </div>
              );
            })}
        </Slider>
      </div>
    </div>
  );
};

export default GalarySection;
