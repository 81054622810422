import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

const NewNumber868AboutUs = (props: any) => {
  const { data } = props;
  let media: any = formatImageUrl(data?.media?.data?.attributes?.url);
  let media_mobile: any = formatImageUrl(
    data?.media_mobile?.data?.attributes?.url
  );

  return (
    <section className="number_868_collection_abut_us_section">
      <div className="collection_container">
        <Row className="collection_row_reverse">
          <Col sm={12} md={12} lg={6}>
            <div className="about_img_section">
              <img src={media} alt={`about img`} width={336} height={679} />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div className="about_text_div">
              <h3>{data?.sub_title}</h3>
              <h2>{data?.title}</h2>
              <p>{data?.content1}</p>
              <p>{data?.content2}</p>

              {/* <img
                src={media_mobile}
                alt={`about img`}
                className="about_text_img"
                width={242}
                height={195}
              /> */}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default NewNumber868AboutUs;
