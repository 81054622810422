import React from "react";

import { formatImageUrl } from "../../../helper/helper";

import Button from "../common/CommonButton/Button";

const NewNumber868Banner = (props: any) => {
  const { data } = props;
  let logoImage: any = formatImageUrl(
    props?.data?.media?.data?.attributes?.url
  );
  let bgImage: any = formatImageUrl(
    window.innerWidth <= 992
      ? props?.data?.background_mobile?.data?.attributes?.url
      : props?.data?.background?.data?.attributes?.url
  );
  return (
    <section
      className="new_number_868_collection_section"
      style={{
        backgroundImage: `url(${bgImage})`,
      }}
    >
      <div className="collection_container">
        <div className="new_number_868_collection_inner_div">
          <div className={`new_number_868_banner_logo_section`}>
            {data?.top_title && <h3>{data?.top_title}</h3>}
            {data?.title1 && <h2>{data?.title1}</h2>}
            {data?.title2 && <h2>{data?.title2}</h2>}
            {data?.title3 && <h2>{data?.title3}</h2>}
            {data?.description && <p>{data?.description}</p>}

            <img
              src={bgImage}
              alt={`about img`}
              width={430}
              height={508}
              className="mobile_banner_img"
            />
            {props?.data?.cta?.link ? (
              <Button
                btnText={props?.data?.cta?.title}
                btnClass={"new_number_btn_buy"}
                btnLink={props?.data?.cta?.link}
                linkOpenState={
                  props?.data?.cta?.open_in_new_tab == true ? "_blank" : ""
                }
              />
            ) : null}
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewNumber868Banner;
