import React from "react";
import { Col, Row } from "react-bootstrap";
import { formatImageUrl } from "../../../helper/helper";

const FoundationDetails = (props: any) => {
  const { data } = props;
  let media: any = formatImageUrl(
    data?.media?.data?.attributes?.url
  );
  return (
    <section className="foundation_details_section">
      <div className="collection_container">
        <Row className="foundation_row">
          <Col sm={12} md={12} lg={6}>
            <div className="foundation_img_section">
              <img
                src={media}
                alt={`foundation img`}
                width={726}
                height={547}
              />
            </div>
          </Col>
          <Col sm={12} md={12} lg={6}>
            <div className="foundation_text_div">
              <h2>{data?.sub_title}</h2>
              <h3>{data?.title}</h3>
              <p>{data?.content1}</p>
              <p>{data?.content2}</p>
              <p>{data?.content3}</p>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default FoundationDetails;
